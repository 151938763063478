/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { NavLink } from 'components';

const nonClickableStyle = { userSelect: 'none' };
const Nav = ({ items, type, className = '' }) => (
  <div className={`nav-container ${className}`}>
    <nav className="nav">
      <ul className="nav-list">
        {
          items.map(({ path, label, mobileOnly, onClick, ...rest }, index) => {
            if (rest.type && rest.type !== type) return null;
            return (
              !mobileOnly ? (
                <li style={nonClickableStyle} onClick={onClick} className="nav-item" key={path || index}>
                  <NavLink
                    {...rest}
                    to={path}
                  >
                    {label}
                  </NavLink>
                </li>
              ) : null
            );
          })
        }
      </ul>
    </nav>
  </div>
);

export default Nav;
