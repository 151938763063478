/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'react-router-dom';
import { ABSOLUTE_URL } from 'settings';

const L = ({ local = true, exactURL, ...props }) => {
  if (ABSOLUTE_URL && !local) {
    const { to, ...p } = props;
    return <a href={exactURL ? to : `${ABSOLUTE_URL}${to}`} {...p} />;
  }
  return <Link {...props} />;
};

export default L;
