import React, { useEffect } from "react";
import Library from "services/library";
import useApiCall from "hooks/useApiCall";
import Exhibitions from "./Exhibitions";

export default function ExhibitionsContainer() {
  const { data, loading } = useApiCall(() => Library.events(), []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return null;
  return <Exhibitions items={data?.results} />;
}
