import React from 'react';
import Select from 'react-select';

const MemoSelect = React.memo(Select);

const selectStyles = {
  option: (styles, { /* data, isDisabled, */isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: 'transparent',
    // eslint-disable-next-line no-nested-ternary
    color: isSelected ? 'black' : isFocused ? 'black' : '#9a9a9a',
    fontSize: 12,
    ':before': {
      display: 'inline-block',
      marginRight: 5,
      content: isSelected ? 'var(--content-check-icon)' : '" "',
      width: 16,
      height: 0,
    },
    ':active': {},
  }),
};
export const sortOptions = [
  { value: 'latest_added', label: 'Latest Additions' },
  { value: 'oldest_added', label: 'Oldest Additions' },
  { value: 'latest_release', label: 'Latest release' },
  { value: 'oldest_release', label: 'Oldest release' },
  { value: 'title', label: 'Title A-Z' },
  { value: 'artist', label: 'Artist A-Z' },
];

export const SortSelect = ({ value, onChange }) => (
  <MemoSelect
    value={value}
    onChange={onChange}
    options={sortOptions}
    styles={selectStyles}
    isSearchable={false}
  />
);
