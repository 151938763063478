import { useHistory } from 'react-router';
import { useCallback } from 'react';
import { useGlobalContext } from 'constants/context';

const useSearch = () => {
  const { push, location: { pathname } } = useHistory();

  const ifNotHomeGoToHome = useCallback(() => {
    if (pathname !== '/') push('/');
  }, [pathname, push]);
  const { setContextState } = useGlobalContext();

  const setSearch = useCallback((searchValue) => { setContextState((state) => ({ ...state, searchValue, filters: [] })); }, [setContextState]);
  return { setSearch, ifNotHomeGoToHome };
};
export default useSearch;
