import React, { useEffect } from 'react';
import { Catalog, FilterNew } from 'components';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import pluralize from 'pluralize';
import classNames from 'classnames';
import { isArrayNotEmpty } from '../Item/Preview/ItemPreview';
import { useHomeFilters } from './hooks/useHomeFilters';
import { useInfiniteFiltersScroll } from './hooks/useInfiniteFiltersScroll';
import { SortSelect } from './SortSelect';

const StyledHome = styled.div`
  padding: 0;
`;

const emptyFunc = () => { };
const getFilter = ({ filterName, options, onClickFilter, isBig = false }) => isArrayNotEmpty(options) && (<div className={isBig ? 'item-filter big' : 'item-filter'}><FilterNew filterName={filterName} options={options} onClick={onClickFilter} /></div>);
const getFilterWithContainer = ({ filterName, options, onClickFilter, infiniteFiltersRef = undefined, FilterLoadMoreButton = null, filtersLoading = false, description = null }) => isArrayNotEmpty(options) && (
  <div ref={infiniteFiltersRef}>
    <div className="filter-description">{description}</div>
    {getFilter({ filterName, options, onClickFilter })}
    {FilterLoadMoreButton}
    {filtersLoading && <div className="loading-text">Loading more...</div>}
  </div>
);

const useFilterChildren = ({ openedFilterCategory, onClickFilter, filtersObject, infiniteScroll }) => {
  const { infiniteRef: infiniteFiltersRef, LoadMoreButton: FilterLoadMoreButton, filters: filtersPaginated, loading: filtersLoading } = infiniteScroll;
  if (openedFilterCategory === 'topics') {
    const sortedByName = filtersPaginated.sort((a, b) => a.name.localeCompare(b.name));
    return [
      getFilter({ options: filtersObject.topFilterTopics, filterName: openedFilterCategory, onClickFilter, isBig: true }),
      getFilterWithContainer({ options: sortedByName, filterName: openedFilterCategory, onClickFilter, infiniteFiltersRef, FilterLoadMoreButton, filtersLoading, description: 'By alphabet' }),
    ];
  }
  if (openedFilterCategory === 'category') {
    return [
      getFilter({ options: filtersObject.categoryItems, filterName: openedFilterCategory, onClickFilter, isBig: true }),
      getFilterWithContainer({ options: filtersObject.subformItems, filterName: 'subform', onClickFilter, description: 'Subform' }),
      getFilterWithContainer({ options: filtersObject.methodItems, filterName: 'artistic_method', onClickFilter, description: 'Art Form' }),
    ];
  }
  return getFilterWithContainer({ options: filtersPaginated, filterName: openedFilterCategory, onClickFilter, infiniteFiltersRef, FilterLoadMoreButton, filtersLoading/* , description: 'By alphabet' */ });
  //  getFilter({ options: filtersObject.filterItems, filterName: openedFilterCategory, onClickFilter });
};

const Home = ({ items, loading, sort, setSort, filters, searchValue, LoadMoreButton, selectedFilters, setSelectedFilters, count, breakpoint }, infiniteCatalogRef) => {
  const { filtersObject, openedFilterCategory, setOpenedFilterCategory, filterCategoryItems, onClickFilter } = useHomeFilters({ filters, loading, selectedFilters, setSelectedFilters });
  useEffect(() => { emptyFunc(searchValue); setOpenedFilterCategory(false); }, [searchValue, setOpenedFilterCategory]);
  const enableInfiniteScroll = ['topics', 'authors_all', 'publishers'].includes(openedFilterCategory);
  const infiniteScroll = useInfiniteFiltersScroll({ filters: enableInfiniteScroll ? filtersObject.filterItems : [], breakpoint, disabled: !enableInfiniteScroll });
  const filterChildren = useFilterChildren({ openedFilterCategory, onClickFilter, filtersObject, infiniteScroll });
  return (
    <StyledHome className="Home">
      <div className="row">
        <div className="item-filter-container">
          <div className="item-filter-wrapper">
            <div className="item-filter">
              <FilterNew className="selected-filter" options={filterCategoryItems} onClick={onClickFilter} />
            </div>
            <div className="items-count">{count}&nbsp;{`${pluralize('publication', count)}`}</div>
          </div>
        </div>
        <div className="select-container">
          <span>Sort By</span>
          <SortSelect value={sort} onChange={setSort} />
        </div>
      </div>
      <div className={classNames('item-filter-container', 'filter-children', { 'filter-children-opened': openedFilterCategory })}>{filterChildren}</div>
      {items.length
        ? (<div ref={infiniteCatalogRef}><Catalog items={items} /></div>)
        : !loading && <div className="no-results">No results{searchValue && <> for <span>&bdquo;</span>{searchValue}<span>&ldquo;</span></>}</div>}
      {LoadMoreButton}
      {loading && <div className="loading-text">Loading more...</div>}
    </StyledHome>
  );
};
const HomeWithRef = React.forwardRef(Home);
const HomeMemo = React.memo(HomeWithRef, isEqual);
export default HomeMemo;
