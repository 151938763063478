import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Link } from 'components';
import Headroom from 'react-headroom';
import classnames from 'classnames';
import useBreakpoint from 'hooks/useBreakpoint';
import styled from 'styled-components';
import { LinkWithIcon } from 'components/Link';
import { useGlobalContext } from 'constants/context';
import useSearch from 'hooks/useSearch';
import usePrevious from 'hooks/usePrevious';
import { Nav } from './components';


const LogoText = styled.span`
  font-size: 22px;
  white-space: nowrap
`;
const SearchIcon = (
  <svg style={{ filter: 'invert(0.5)' }} className="search-icon" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="21px" height="21px" viewBox="0 0 17 17" version="1.1">
    <defs />
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-219.000000, -39.000000)" stroke="#FFFFFF" strokeWidth="0.1" fill="#131413">
        <g transform="translate(216.000000, 36.000000)">
          <path d="M14.5584227,15.1707741 C13.4450565,16.1542476 11.9791244,16.7480429 10.3832995,16.7480429 C6.8576399,16.7480429 4,13.890403 4,10.3647434 C4,6.83908379 6.8576399,4 10.3832995,4 C13.890403,4 16.7480429,6.83908379 16.7480429,10.3647434 C16.7480429,11.9605683 16.1542476,13.4265004 15.1893302,14.5584227 L19.9025805,19.2716729 C20.0324732,19.4015657 20.0324732,19.4943462 19.9025805,19.6242389 L19.6242389,19.9025805 C19.4943462,20.0324732 19.4201218,20.0324732 19.2902291,19.9025805 L14.5584227,15.1707741 Z M10.3832995,4.87213685 C7.34009858,4.87213685 4.89069295,7.34009858 4.89069295,10.3647434 C4.89069295,13.3893882 7.34009858,15.85735 10.3832995,15.85735 C13.4079443,15.85735 15.85735,13.3893882 15.85735,10.3647434 C15.85735,7.34009858 13.4079443,4.87213685 10.3832995,4.87213685 Z" id="search-icon" />
        </g>
      </g>
    </g>
  </svg>
);

// const baseUrl = process.env.REACT_APP_EDCAT_URL;
const headerItems = ({ onClickSearchIcon }) => [
  {
    label: SearchIcon,
    onClick: onClickSearchIcon,
  },
];


const StyledInput = styled.input`
  margin-top:3px;
  padding: 13px;
  background-color: #FFFFFF;
  width: 100%;
  border: none;
  outline: none;
  ${'' /* margin-top: -2px; */}
  margin-left: -15px;
  caret-color: #2000FF;
  &::placeholder,
  &::-webkit-input-placeholder {
    color:#757575 !important;
  };
  :-ms-input-placeholder {
    color:#757575 !important;
  }
`;

const TransitionDiv = styled.div`
display: flex;
width:100%;
opacity:1;
transition: all 0.4s;
align-items: center;
${(props) => !props.visible && `
  visibility: hidden;
  opacity:0;
`}
.nav-container{
  display:flex;
  >.nav{
    margin:auto;
  }
  +* .search-icon{
    display:none;
  }
}
`;

const FullWidthDiv = styled.div`
  width: 100%;
`;
const FormSearchContainer = styled.form`
  width: 100%;
  display: flex;
  svg {
    cursor:pointer;
    margin:auto;
    margin-left:30px;
    height:30px;
  }
`;
const HeaderComponent = styled.header`
.mobile-nav .form-search {
  input {
    background:#f2f5f4;
    &::placeholder{
      font-size: 26px;
    }
  }
  svg:not(.search-icon){
    display:none;
  }
  svg{
    margin-left:-30px;
    pointer-events:none;
  }
  
${'' /* background: blue; */}
}
`;

const denyClick = (e) => { e.preventDefault(); e.stopPropagation(); };

const Header = ({ breakpoint }) => {
  const searchInputRef = useRef();
  const [searchText, setSearchText] = useState('');
  const { contextState: { searchValue }, setContextState } = useGlobalContext();
  const { searchValue: prevSearchValue } = usePrevious({ searchValue });
  if (prevSearchValue !== searchValue && searchValue !== searchText) setSearchText(searchValue); // update state if context changed
  const onSearchValueChanged = useCallback((e) => setSearchText(e.target.value), []);
  const { setSearch, ifNotHomeGoToHome } = useSearch();
  const onSubmitSearch = useCallback((e) => { e.preventDefault(); e.stopPropagation(); setSearch(searchText); ifNotHomeGoToHome(); }, [ifNotHomeGoToHome, searchText, setSearch]);
  const onClickHome = () => {
    setSearchText('');
    setSearch('');
    setContextState((state) => ({ ...state, filters: [] }));
  };
  const [isDisplaySearchBar, setDisplaySearchBar] = useState(false);
  // console.log(isDisplaySearchBar);
  const SearchComponent = (className) => (
    <FormSearchContainer className={`form-search ${className || ''}`} onClick={denyClick} onSubmit={onSubmitSearch}>
      <StyledInput ref={searchInputRef} name="search" placeholder="search the library" value={searchText} onChange={onSearchValueChanged} />
      {SearchIcon}
    </FormSearchContainer>
  );
  const headerItemsMemo = useMemo(() => headerItems({ onClickSearchIcon: ['xs', 'sm'].includes(breakpoint) ? () => setDisplaySearchBar((prev) => !prev) : onSubmitSearch }), [breakpoint, onSubmitSearch]);

  return (
    <HeaderComponent className={classnames('Header')}>
      <Headroom
        disableInlineStyles
        // disable={alwaysShow}
        upTolerance={5}
        downTolerance={10}
      >
        <div className="header-container">
          <div className={`logo-img-container ${isDisplaySearchBar ? 'hide-on-767' : ''}`}>
            <Link className="logo-link" to="/" onClick={onClickHome}>
              <LogoText className="logo-text">BaseCamp Library</LogoText>
            </Link>
          </div>
          <FullWidthDiv>
            {/* <TransitionDiv visible={isDisplaySearchBar}>
              {SearchComponent}
              <LinkWithIcon marginTopIcon={-2} className="logo-link hide-on-mobile" to="/" local={false}>
                <span style={{ fontSize: 15, whiteSpace: 'nowrap' }}>BaseCamp Library</span>
              </LinkWithIcon>
            </TransitionDiv> */}
            <TransitionDiv className="search-container" visible>
              <Nav type={breakpoint} items={headerItemsMemo} />
              {SearchComponent(isDisplaySearchBar ? '' : 'hide-on-767')}
              <div className="hide-on-mobile">
              <Link  to="/">
                <span style={{ fontSize: 15, whiteSpace: 'nowrap', marginRight: 45 }}>Catalog</span>
              </Link>
              <Link  to="/exhibitions">
                <span style={{ fontSize: 15, whiteSpace: 'nowrap', marginRight: 45 }}>Exhibitions</span>
              </Link>
              </div>
              <LinkWithIcon marginTopIcon={-2} className="logo-link hide-on-767" to="/" local={false}>
                <span style={{ fontSize: 15, whiteSpace: 'nowrap' }}>BaseCamp Website</span>
              </LinkWithIcon>

            </TransitionDiv>
          </FullWidthDiv>
        </div>
      </Headroom>
      <div className="hide-on-not-mobile header-content">
        <Link  to="/">
          <span style={{ fontSize: 15, whiteSpace: 'nowrap', marginRight: 45 }}>Catalog</span>
        </Link>
        <Link  to="/exhibitions">
          <span style={{ fontSize: 15, whiteSpace: 'nowrap', marginRight: 45 }}>Exhibitions</span>
        </Link>
      </div>
    </HeaderComponent>
  );
};
export const WithBreakpoint = (Component) => function WithBreakpoint(props) { return <Component {...props} breakpoint={useBreakpoint().breakpoint} />; };
const MemoHeader = React.memo(Header);
export default WithBreakpoint(MemoHeader);
