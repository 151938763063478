import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { className, style, onClick, onClickAction } = props;
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      className={`navButtons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={(e) => {
        onClick(e);
        onClickAction();
      }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="Fill-1"
          fill="#000000"
          transform="translate(12.808500, 12.000000) rotate(-180.000000) translate(-12.808500, -12.000000) "
          points="17.773 2 7 11.837 17.765 22 18.617 21.097 8.823 11.851 18.61 2.917"
        ></polygon>
      </g>
    </svg>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick, onClickAction } = props;
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      className={`navButtons ${className}`}
      style={{ ...style, display: "block" }}
      onClick={(e) => {
        onClick(e);
        onClickAction();
      }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon
          id="Fill-1"
          fill="#000000"
          points="15.773 2 5 11.837 15.765 22 16.617 21.097 6.823 11.851 16.61 2.917"
        ></polygon>
      </g>
    </svg>
  );
};
const Carousal = ({
  items,
  desktop,
  onNextClick = () => {},
  onPrevClick = () => {},
  initialSlide
}) => {
  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  if (desktop)
    settings = {
      ...settings,
      nextArrow: <NextArrow onClickAction={onNextClick} />,
      prevArrow: <PrevArrow onClickAction={onPrevClick} />,
      fade: true,
    };
  return (
    <Slider {...settings} initialSlide={initialSlide||0}>
      {items.map((item) =>
        desktop ? (
          <div>
            <img src={item.url} className="image" alt="title" />
          </div>
        ) : (
          <img src={item.url} alt="title" />
        )
      )}
    </Slider>
  );
};

export default Carousal;
