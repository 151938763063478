import React from "react";
import { Catalog } from "components";

const Exhibitions = ({ items }) => {
  return (
    <div className="Exhibitions">
      <h1 className="hide-on-mobile">Exhibitions</h1>

      {items && items.length && (
        <div>
          <Catalog to="exhibitions/" passedClass="item-container-xl" items={items} />
        </div>
      )}
    </div>
  );
};

export default Exhibitions;
