import { useRef } from 'react';
import Library from 'services/library';
import useApiCall from 'hooks/useApiCall';
import get from 'lodash/get';

const emptyArr = [];

export const useFiltersFetch = () => {
  const filtersRef = useRef([{ values: [], type: 'category' }, { values: [], type: 'subform' }, { values: [], type: 'artistic_method' }, { values: [], type: 'style' }, { values: [], type: 'topics' }]);
  const { error, data, component, refetch, loading } = useApiCall(() => Library.filters(), []);
  const filters = get(data, 'filters', emptyArr);
  if (!loading) filtersRef.current = filters;

  return { refetch, error, filters: filtersRef.current, component, loading };
};
