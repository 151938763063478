import React from 'react';
import Spinner from 'react-spinner-material';
import { FadeIn } from 'components';

const Loading = () => (
  <FadeIn duration={300}>
    <Spinner
      size={60}
      // spinnerColor="#000" spinnerWidth={5}
      visible
    />
  </FadeIn>
);

export default Loading;
