import React, { useState, useEffect, useCallback } from 'react';
import { Error, Loading, AbsoluteCenter } from 'components';
import { useGlobalContext } from 'constants/context';
import useBreakpoint from 'hooks/useBreakpoint';
import Home from './Home';
import { useInfiniteCatalogScroll } from './hooks/useInfiniteCatalogScroll';
import { useIsInitialLoading } from './hooks/useIsInitialLoading';
import { useFiltersFetch } from './hooks/useFiltersFetch';
import { sortOptions } from './SortSelect';

export default function HomeContainer() {
  useEffect(() => { window.scrollTo(0, 0); }, []);
  const [sort, setSort] = useState(sortOptions[2]);

  const { contextState: { searchValue, filters: selectedFilters = [] }, setContextState } = useGlobalContext();
  const setSelectedFilters = useCallback((newValue) => setContextState((contextState) => ({ ...contextState, filters: (typeof newValue === 'function') ? newValue(contextState.filters || []) : newValue, searchValue: '' })), [setContextState]);
  const { /* loading: loadingFilters, */ filters } = useFiltersFetch();
  const { breakpoint } = useBreakpoint();
  const { LoadMoreButton, infiniteRef, error, items, refetch, loading, count } = useInfiniteCatalogScroll({ breakpoint, searchValue, sort: sort.value, filters: selectedFilters });
  const isInitialLoading = useIsInitialLoading(loading);

  if (isInitialLoading) return (<AbsoluteCenter><Loading center /></AbsoluteCenter>);
  if (error) return <Error error={error} retry={refetch} />;

  return (
    <Home
      ref={infiniteRef}
      loading={loading}
      items={items}
      sort={sort}
      setSort={setSort}
      filters={filters}
      searchValue={searchValue}
      LoadMoreButton={LoadMoreButton}
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      count={count}
      breakpoint={breakpoint}
    />
  );
}
