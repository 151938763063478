import React from 'react';
import Button from 'components/Button';
import isEqual from 'lodash/isEqual';
import useFunctionToRefCB from 'hooks/useFunctionToRefCB';
import classnames from 'classnames';

const FILTER_TOGGLE_ICON_COLOR = '#1E201D';
const FILTER_SELECTED_BUTTON_ICON_COLOR = 'red';

const CloseIcon = ({ color }) => (
  <svg style={{ width: '9px', height: '9px', fill: color }} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 22.88 22.88" space="preserve">
    <path d="M0.324,1.909c-0.429-0.429-0.429-1.143,0-1.587c0.444-0.429,1.143-0.429,1.587,0l9.523,9.539  l9.539-9.539c0.429-0.429,1.143-0.429,1.571,0c0.444,0.444,0.444,1.159,0,1.587l-9.523,9.524l9.523,9.539  c0.444,0.429,0.444,1.143,0,1.587c-0.429,0.429-1.143,0.429-1.571,0l-9.539-9.539l-9.523,9.539c-0.444,0.429-1.143,0.429-1.587,0  c-0.429-0.444-0.429-1.159,0-1.587l9.523-9.539L0.324,1.909z" />
  </svg>
);
const MemoIcon = React.memo(CloseIcon);

export const FilterButton = ({ iconAtLeft, isSelected, name, onClick, className = '' }) => {
  const iconColor = iconAtLeft ? FILTER_TOGGLE_ICON_COLOR : FILTER_SELECTED_BUTTON_ICON_COLOR;

  return (
    <div className={classnames('filter-option-container', className, { 'filter-option-container-selected': isSelected })}>
      <Button onClick={onClick}>
        <div className="filter-button-content">
          {isSelected && iconAtLeft && <div className="filter-left-icon"><MemoIcon color={iconColor} /></div>}
          <div className="filter-name">{name}</div>
          {isSelected && !iconAtLeft && <div className="filter-right-icon"><MemoIcon color={iconColor} /></div>}
        </div>
      </Button>
    </div>
  );
};
const MemoFilterButton = React.memo(FilterButton);
export const PreFilterButton = ({ onClick, ...props }) => {
  const MemoOnClick = useFunctionToRefCB(onClick);
  return (<MemoFilterButton {...props} onClick={MemoOnClick} />);
};
const MemoPreFilterButton = React.memo(PreFilterButton);


const emptyArr = [];
const emptyFunc = () => { };
export const Filter = (props) => {
  const { options = emptyArr, filterName, className, onClick = emptyFunc } = props;
  return (
    <div className={className}>
      {options.map((option) => (
        <MemoPreFilterButton
          key={`${option.id}_${option.name}`}
          onClick={() => onClick(option, option.type || filterName)}
          {...option}
        />
      ))}
    </div>
  );
};
const MemoFilter = React.memo(Filter, isEqual);
export default MemoFilter;
