import React from 'react';

const NotFound = () => (
  <div>
    <h1>404 Page Not Found</h1>
    <p>The page you requested was not found</p>
  </div>
);

export default NotFound;
