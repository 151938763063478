import React, { useState, useMemo, useRef } from 'react';
import { Error, Loading, AbsoluteCenter } from 'components';
import { isEqual } from 'lodash';
import { usePreviousSync } from './usePrevious';

export default function useApiCall(call, deps = []) {
  const [, rerender] = useState();
  const saltRef = useRef(true);
  const dataRef = useRef(null);
  const loadingRef = useRef(true);
  const errorRef = useRef(null);
  const { current: refetch } = useRef(() => { saltRef.current = !saltRef.current; rerender(Math.random()); });
  const salt = saltRef.current;
  const depsToFetch = [salt, ...deps];

  if (!isEqual(usePreviousSync(depsToFetch), depsToFetch)) {
    loadingRef.current = true;
    errorRef.current = null;
    call()
      .then((data) => { dataRef.current = data; })
      .catch((error) => { errorRef.current = error; })
      .finally(() => { loadingRef.current = false; rerender(Math.random()); });
  }

  const data = dataRef.current;
  const loading = loadingRef.current;
  const error = errorRef.current;
  const component = useMemo(() => {
    if (loading) return (<AbsoluteCenter><Loading center /></AbsoluteCenter>);
    if (error) return <Error error={error} retry={refetch} />;
    return null;
  }, [error, loading, refetch]);

  return {
    data,
    loading,
    error,
    refetch,
    component,
  };
}
