import React from 'react';

const styles = {
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

const AbsoluteCenter = ({ children, style }) => (
  <div style={{ ...styles, style }}>
    {children}
  </div>
  );

export default AbsoluteCenter;
