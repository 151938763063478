/* eslint-disable no-unused-expressions */
import axios from 'axios';
import { API_URL, API_KEY, MOCK_API, DEBUG } from 'settings';
import forEach from 'lodash/forEach';

class Library {
  baseUrl = API_URL


  _call = async ({ method = 'get', path, headers = {}, params, data, ...rest }) => {
    DEBUG && console.log(`[Library] Calling ${this.baseUrl}${path}`, { headers, params, data, rest });
    try {
      if (MOCK_API) {
        DEBUG && console.log(`[Library] Return mocked response ${this.baseUrl}${path}`);
        // const mocks = await import('./mocks');
        // return mocks.edcat[`${method}.${path}`];
      }
      Object.assign(headers, { key: API_KEY });

      if (this.token) {
        Object.assign(headers, {
          Authorization: `Token ${this.token}`,
        });
      }
      // await delay(1500);
      const res = await axios.request({
        url: `${this.baseUrl}${path}`,
        method,
        headers,
        params,
        data,
        ...rest,
      });
      DEBUG && console.log(`[Library] Success ${this.baseUrl}${path}`, { res });
      return res.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.logout();
      }
      DEBUG && console.log(`[Library] Error ${this.baseUrl}${path}`, { error });
      const errorMessage = error.response ? error.response.data.error || error.message : error.message;
      const err = new Error(errorMessage);
      err.raw = error;
      throw err;
    }
  }

  items = ({ limit, offset, query, sort, filters }) => {
    const filtersObject = {};
    forEach(filters, (option) => {
      if (!Array.isArray(filtersObject[option.type])) filtersObject[option.type] = [];
      filtersObject[option.type].push(option.id);
    });
    forEach(Object.keys(filtersObject), (key) => { filtersObject[key] = filtersObject[key].join(','); });
    return this._call({
      path: '/items/',
      params: {
        limit,
        offset,
        query,
        sort,
        ...filtersObject,
      },
    });
  }

  filters = () => this._call({ path: '/items/filters/' })

  events = () => this._call({ path: '/events/' })

  eventDetails = ({ slug }) => this._call({ path: `/events/${slug}/` })

  item = ({ slug }) => this._call({ path: `/items/${slug}/` })
}

export default new Library();
