import React, { useEffect, useState } from "react";
import { Carousal } from "components";

const SliderModal = ({ title, artists, slides, active, onClose }) => {
  const [start, setStart] = useState(active);
  const [fadeOut, setFadeOut] = useState(false);

  const onNextClick = () => {
    setStart(start + 1 >= slides.length ? 0 : start + 1);
  };

  const onPrevClick = () => {
    setStart(start - 1 >= 0 ? start - 1 : slides.length - 1);
  };

  useEffect(() => {}, [start]);

  const onClickClose = () => {
    setFadeOut(true);
    setTimeout(() => {
      onClose();
    }, 800);
  };
  return (
    <div className={`slider-container ${fadeOut ? "slider-container2" : ""}`}>
      <h2>{artists.map((item, i)=> (item.name+ (i === artists.length - 1 ? ": " : ", ")))}{title}</h2>
      <div className="cross" onClick={onClickClose}>
        <svg width="110px" height="110px" viewBox="0 0 44 44">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon
              fill="#000000"
              points="21 3 12.46 11.54 3.92 3 3 3.92 11.54 12.46 3 21 3.92 21.92 12.46 13.38 21 21.92 21.92 21 13.38 12.46 21.92 3.92"
            ></polygon>
          </g>
        </svg>
      </div>
      <div className="slider">
        <div className="image-container">
          <Carousal
            onNextClick={onNextClick}
            onPrevClick={onPrevClick}
            items={slides}
            desktop
            initialSlide={active}
          />
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: slides[start].subtitle }}></p>
    </div>
  );
};

export default SliderModal;
