import React, { useEffect } from 'react';
// import Edcat from 'services/edcat';
import useApiCall from 'hooks/useApiCall';
import Library from 'services/library';
import get from 'lodash/get';
import { ItemPreview } from '.';
// import StoryPreview from './StoryPreview';
// import { normalizeOptions } from '../utils';
// import NotFoundStoryMessage from '../components/NotFoundStoryMessage';

const ItemPreviewContainer = (
  props,
) => {
  const slug = get(props, 'match.params.slug', '');
  // useEffect(() => { window.scroll({ top: 0, left: 0, behavior: 'smooth' }); }, [slug])
  useEffect(() => { window.scrollTo(0, 0); }, [slug]);

  // get data for story
  const { data, component, error } = useApiCall(() => Library.item({ slug }), [slug]);

  const story = React.useMemo(() => {
    if (!data) return null;
    return {
      ...data,
      relatedItems: data.related_items,
      // featuredItems: Array.isArray(data.items) ? data.items.slice(0, 6) : [],
      // items: Array.isArray(data.items) ? data.items.slice(6) : [],
      // keywords: normalizeOptions(data.keywords),
    };
  }, [data]);
  if (error && error.message && typeof error.message === 'string' && typeof error.message.includes === 'function' && error.message.includes('404')) {
    // return <NotFoundStoryMessage />;
    return 'Not found';
  }
  if (component) {
    return component;
  }

  return (
    <ItemPreview story={story} />
  );
};

export default ItemPreviewContainer;
