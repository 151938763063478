/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import settings from 'settings';
import { theme } from 'theme';

const cursorPointer = { cursor: 'pointer', display: 'flex' };
// NavLink
const L = ({ local, ...props }) => {
  if (!props.to) return <div style={cursorPointer}>{props.children}</div>;
  if (settings.ABSOLUTE_URL && props.to !== '/' && !local) {
    const { to, ...p } = props;
    return <a href={`${settings.ABSOLUTE_URL}${to}`} {...p} />;
  }
  return (
    <NavLink {...props} activeStyle={{ color: theme.activeLinkColor }} />
  );
};

export default L;
