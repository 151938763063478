import get from 'lodash/get';
import merge from 'lodash/merge';
import config from './config.json';

const {
  REACT_APP_API_URL: ENV_API_URL,
  REACT_APP_DEBUG,
  REACT_APP_MOCK_API,
  REACT_APP_ABSOLUTE_URL: ENV_ABSOLUTE_URL,
  REACT_APP_RECAPTCHA_SITEKEY: ENV_RECAPTCHA_SITEKEY,
  REACT_APP_API_KEY: ENV_API_KEY,
  ...rest
} = process.env;

// eslint-disable-next-line eqeqeq
const ENV_DEBUG = !!(REACT_APP_DEBUG === 'true' || REACT_APP_DEBUG == 1);

export const MOCK_API = REACT_APP_MOCK_API === 'true';

const { SEO: DEFAULT_SEO } = config;

const settings = {
  ...rest,
  ...config,
  MOCK_API,
  SEO: merge(DEFAULT_SEO, get(window.edcatConfig, 'SEO')),
  API_URL: get(window.edcatConfig, 'API_URL', ENV_API_URL),
  API_KEY: get(window.edcatConfig, 'API_KEY', ENV_API_KEY),
  DEBUG: get(window.edcatConfig, 'DEBUG', ENV_DEBUG),
  ABSOLUTE_URL: get(window.edcatConfig, 'ABSOLUTE_URL', ENV_ABSOLUTE_URL),
  RECAPTCHA_SITEKEY: get(window.edcatConfig, 'RECAPTCHA_SITEKEY', ENV_RECAPTCHA_SITEKEY),
};

export const { SEO, API_URL, DEBUG, ABSOLUTE_URL, RECAPTCHA_SITEKEY, API_KEY } = settings;

export default settings;
