import { useEffect, useRef } from 'react';
// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious(value, initialValue = {}) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function usePreviousSync(value, initialValue = {}) {
  const prevRef = useRef();
  const ref = useRef(initialValue);
  prevRef.current = ref.current;
  ref.current = value;
  return prevRef.current;
}

export default usePrevious;
