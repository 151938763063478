import React, { useState } from "react";
import { Catalog } from "components";

const ExhibitionPreview = ({ data, items }) => {
  const [fullDesc, setFullDesc] = useState(false);
  return (
    <div className="Exhibitions">
      <div className="ItemContainer">
        <img className="main-img" src={data.image} alt="preview item" />
        <h1>{data.title}</h1>
        <p>{data.event_type}</p>
        <p>{data.dates}</p>
        {data.venues.map((item) => (
          <p>{item.name}</p>
        ))}
        <p>{data.address}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: fullDesc
              ? data.description
              : data.description.substring(0, 174) + " ...",
          }}
          className="content"
        ></p>
        <div style={{ width: "100%" }} onClick={() => setFullDesc(!fullDesc)}>
          <p className="desc-toggle">
            {fullDesc ? "hide the description" : "show full description"}
          </p>
        </div>
        <div className="subItemsContainer">
          {data.items && data.items.length > 0 && (
            <div>
              <h3>Books featured in this exhibition</h3>
              <Catalog items={data.items} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExhibitionPreview;
