import React from 'react';
import { Link } from 'components';
import map from 'lodash/map';

const Item = ({ slug, image, title, artists, price, dates, to }) => {
  const formatArtistNames = (artists) => {
    const artistArr = map(artists, (a) => a.name);
    return artistArr.length > 3 ? (
      `${artistArr.slice(0, 3).join(', ')}\u00A0+${artistArr.length - 3}`
    )
      : artistArr.join(', ');
  };

  return (
    <div className="Item">
      <Link to={`${to ? to : '/item/'}${slug}`} className="Link item-link">
        <div className="item-img-container">
          {/* 3x4 #c2c2c2 https://png-pixel.com/ */}
          <img src={image} alt={title} onError={(e) => { e.target.onerror = null; e.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAQAAADhJE2MAAAAEElEQVR42mM89J8BCBixUQBwKAcJsCakLQAAAABJRU5ErkJggg=='; }} className="item-img" />
        </div>
        <div className="item-text">
          {
            (Array.isArray(artists) && artists.length) ? (
              <div className="item-artists">
                {formatArtistNames(artists)}
              </div>
            )
              : <div className="item-artists">&nbsp;</div>
          }
          {dates && <div className="item-title">{dates}</div>}
          <div className="item-title">{title}</div>
          {price ? <div className="price-container"><div className="item-price">{price}</div></div> : null}
        </div>
      </Link>
    </div>
  );
};
const MemoItem = React.memo(Item);
export default MemoItem;
