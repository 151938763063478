import React, { useState, useCallback, useRef, useMemo } from 'react';
import { Button } from 'components';
import isEqual from 'lodash/isEqual';
import { usePreviousSync } from 'hooks/usePrevious';
import useApiCall from 'hooks/useApiCall';
import { useInfiniteScroll as useInfiniteScrollHook } from 'react-infinite-scroll-hook';
import take from 'lodash/take';

const LIMIT = 100;
const interval = 50;
const paginateFilters = async ({ limit, filters }) => {
  if (limit !== LIMIT && limit !== 0) await new Promise((resolve) => window.setTimeout(resolve, interval));
  const paginated = take(filters, limit);
  return { results: paginated, count: filters.length, next: paginated.length !== filters.length };
};

const emptyArr = [];
export const useInfiniteFiltersScroll = ({ filters, breakpoint, limitStep = LIMIT, disabled }) => {
  const filtersRef = useRef(emptyArr);
  const limitRef = useRef(limitStep);
  const countRef = useRef(0);
  const [, rerender] = useState(false);

  const resetMemoResultsProps = { filters };
  const resetResultsProps = usePreviousSync(resetMemoResultsProps, resetMemoResultsProps);
  const shouldResetResults = !isEqual(resetMemoResultsProps, resetResultsProps);
  if (shouldResetResults && !disabled) { filtersRef.current = []; limitRef.current = limitStep; rerender(Math.random()); window.scrollTo(0, 0); }
  const limit = limitRef.current;

  const { error, data, component, refetch, loading } = useApiCall(() => paginateFilters({ limit, filters }), [limit, filters]);

  if (!loading) filtersRef.current = data.results;
  if (!loading) countRef.current = data.count;
  const onLoadMore = useCallback(() => { limitRef.current += limitStep; rerender(Math.random()); }, [limitStep]);
  const hasNextPage = (data || {}).next;
  const infiniteRef = useInfiniteScrollHook({ loading: (breakpoint === 'xs') || loading || disabled, hasNextPage, onLoadMore, scrollContainer: 'window', threshold: 500 /* px */ });
  const LoadMoreButton = useMemo(() => ((breakpoint === 'xs' && hasNextPage && !loading) ? (<Button onClick={onLoadMore} className="load-more-button">Load more</Button>) : null), [breakpoint, hasNextPage, loading, onLoadMore]);
  return { infiniteRef, LoadMoreButton, refetch, error, filters: filtersRef.current, count: countRef.current, component, loading };
};
