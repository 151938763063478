import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import { Home, NotFound, ItemPreview, ExhibitionsList, ExhibitionPreview } from './pages';
import ThemeProvider from './theme';
import { GlobalContextProvider } from './constants/context';

const App = () => (
  <ThemeProvider>
    <Router>
      <GlobalContextProvider>
        <MainLayout>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/item/:slug" exact component={ItemPreview} />
            <Route path="/exhibitions" exact component={ExhibitionsList} />
            <Route path="/exhibitions/:slug" exact component={ExhibitionPreview} />
            <Route component={NotFound} />
          </Switch>
        </MainLayout>
      </GlobalContextProvider>
    </Router>
  </ThemeProvider>
);

export default App;
