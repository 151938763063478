/* eslint-disable no-nested-ternary */
import { useState, useCallback } from 'react';
import find from 'lodash/find';
import filter from 'lodash/filter';
import get from 'lodash/get';
import take from 'lodash/take';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { sortBy } from 'lodash';

const filterCategoryItems_ = [
  { name: 'Topics', id: 'topics', type: 'filter-category', iconAtLeft: true, className: 'toggle-button' },
  { name: 'Authors', id: 'authors_all', type: 'filter-category', iconAtLeft: true, className: 'toggle-button' },
  { name: 'Publishers', id: 'publishers', type: 'filter-category', iconAtLeft: true, className: 'toggle-button' }];

export const useHomeFilters = ({ filters, selectedFilters, setSelectedFilters }) => {
  const [openedFilterCategory, setOpenedFilterCategory] = useState(false);
  const onClickDeSelectedFilter = useCallback(({ id, name }, type) => {
    setOpenedFilterCategory(false);
    // eslint-disable-next-line array-bracket-spacing
    setSelectedFilters((/* selectedFilters */) => [/* ...selectedFilters, */ { type, id, name }]);
  }, [setSelectedFilters]);
  const onClickSelectedFilter = useCallback(({ id }, type) => {
    setOpenedFilterCategory(false);
    setSelectedFilters((selectedFilters) => selectedFilters.filter((option) => !(option.id === id && option.type === type)));
  }, [setSelectedFilters]);
  const onClickFilter = useCallback(({ id, name }, type) => {
    if (type === 'filter-category') {
      setOpenedFilterCategory((prevId) => ((prevId === id) ? false : id));
      return;
    }
    if (find(selectedFilters, { type, id })) onClickSelectedFilter({ id, name }, type);
    else onClickDeSelectedFilter({ id, name }, type);
  }, [onClickDeSelectedFilter, onClickSelectedFilter, selectedFilters]);

  const setFilterCategory = (option) => { setOpenedFilterCategory(option); };

  const filterCategoryItems = map([...filterCategoryItems_, ...selectedFilters], (i) => {
    const isFilterCategorySelected = (i.id === openedFilterCategory && i.type === 'filter-category');
    return ({ ...i, isSelected: isFilterCategorySelected || i.type !== 'filter-category' });
  });
  const selectedFiltersIds = map(selectedFilters, (i) => i.id);
  const filtersObject = {};
  const getFiltersByFilterCategory = (category) => filter(get(find(filters, { type: category }), 'values'), (item) => item.name && !selectedFiltersIds.includes(item.id));
  if (openedFilterCategory === 'topics') {
    filtersObject.topFilterTopics = take(openedFilterCategory && orderBy(filter(get(find(filters, { type: openedFilterCategory }), 'values'), (item) => item.name && !selectedFiltersIds.includes(item.id)), 'number_of_items', 'desc'), 40);
    filtersObject.filterItems = getFiltersByFilterCategory(openedFilterCategory);
  } else if (openedFilterCategory === 'category') {
    filtersObject.categoryItems = getFiltersByFilterCategory(openedFilterCategory);
    filtersObject.subformItems = getFiltersByFilterCategory('subform');
    filtersObject.methodItems = getFiltersByFilterCategory('artistic_method');
  } else filtersObject.filterItems = sortBy(getFiltersByFilterCategory(openedFilterCategory), 'name');

  return { filtersObject, openedFilterCategory, filterCategoryItems, onClickFilter, setOpenedFilterCategory, onClickDeSelectedFilter, onClickSelectedFilter, setFilterCategory };
};
