import React from 'react';
import { Header, Footer } from 'components';


const MainLayout = ({ children, ...rest }) => (
  <div className="App">
    <App {...rest}>
      {children}
    </App>
  </div>
  );


const App = ({ children, ...rest }) => (
  <>
    <Header {...rest} />
    <main className="main">
      {children}
    </main>
    <Footer {...rest} />
  </>
);

export default MainLayout;
